import React from 'react';
import { Card, CardContent, Typography, Box, List, ListItem, ListItemText } from '@mui/material';
import { fetchBookmarks } from '../api/bookmarks';
import { Bookmark } from '../types/bookmark';
import BookmarkIcon from '@mui/icons-material/Bookmark';

const YesterdayBookmarks: React.FC = () => {
  const [bookmarks, setBookmarks] = React.useState<Bookmark[]>([]);
  const [error, setError] = React.useState<string | null>(null);

  React.useEffect(() => {
    const loadYesterdayBookmarks = async () => {
      try {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        const data = await fetchBookmarks(yesterday);
        setBookmarks(data);
        setError(null);
      } catch (err) {
        const errorMessage = err instanceof Error ? err.message : 'Failed to load bookmarks';
        setError(errorMessage);
        console.error('Error loading bookmarks:', err);
      }
    };

    loadYesterdayBookmarks();
  }, []);

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Box display="flex" alignItems="center" gap={1} mb={2}>
          <BookmarkIcon color="primary" />
          <Typography variant="h5" component="h2">
            Yesterday's Bookmarks
          </Typography>
        </Box>

        {error ? (
          <Typography color="error">{error}</Typography>
        ) : bookmarks.length === 0 ? (
          <Typography>No bookmarks saved yesterday.</Typography>
        ) : (
          <List>
            {bookmarks.map((bookmark, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={bookmark.title}
                  secondary={
                    <a href={bookmark.url} target="_blank" rel="noopener noreferrer">
                      {bookmark.url}
                    </a>
                  }
                />
              </ListItem>
            ))}
          </List>
        )}
      </CardContent>
    </Card>
  );
};

export default YesterdayBookmarks;
