import React from 'react';
import { Card, CardContent, Typography, Box, ToggleButton, ToggleButtonGroup, CircularProgress } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { fetchAnalytics } from '../api/bookmarks';
import InsightsIcon from '@mui/icons-material/Insights';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface AnalyticsData {
  total_bookmarks: number;
  period: string;
  tag_distribution: Record<string, number>;
}

type Period = 'week' | 'month' | 'year';

const PERIOD_LABELS: Record<Period, string> = {
  week: 'Last 7 Days',
  month: 'Last 30 Days',
  year: 'Last Year'
};

const BookmarkAnalytics: React.FC = () => {
  const [period, setPeriod] = React.useState<Period>('week');
  const [analyticsData, setAnalyticsData] = React.useState<AnalyticsData | null>(null);
  const [error, setError] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const loadAnalytics = async () => {
      try {
        setIsLoading(true);
        setError(null);
        const data = await fetchAnalytics(period);
        setAnalyticsData(data);
      } catch (err) {
        const errorMessage = err instanceof Error ? err.message : 'Failed to load analytics data';
        setError(errorMessage);
        console.error('Error loading analytics:', err);
      } finally {
        setIsLoading(false);
      }
    };

    loadAnalytics();
  }, [period]);

  const handlePeriodChange = (_: React.MouseEvent<HTMLElement>, newPeriod: Period | null) => {
    if (newPeriod) {
      setPeriod(newPeriod);
    }
  };

  const chartData = React.useMemo(() => {
    const emptyChart = {
      labels: [],
      datasets: [{
        label: 'No Data Available',
        data: [],
        backgroundColor: 'rgba(25, 118, 210, 0.5)',
        borderColor: 'rgba(25, 118, 210, 1)',
        borderWidth: 1,
      }]
    };

    if (!analyticsData || !analyticsData.tag_distribution) {
      return emptyChart;
    }

    try {
      // Ensure tag_distribution is an object and not null/undefined
      const tagDistribution = analyticsData.tag_distribution || {};
      
      const labels = Object.keys(tagDistribution);
      const data = Object.values(tagDistribution);

      if (labels.length === 0) {
        return emptyChart;
      }

      return {
        labels,
        datasets: [
          {
            label: 'Bookmarks by Tag',
            data,
            backgroundColor: 'rgba(25, 118, 210, 0.5)',
            borderColor: 'rgba(25, 118, 210, 1)',
            borderWidth: 1,
          },
        ],
      };
    } catch (error) {
      console.error('Error creating chart data:', error);
      return emptyChart;
    }
  }, [analyticsData]);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        },
      },
    },
  };

  const renderContent = () => {
    if (error) {
      return <Typography color="error">{error}</Typography>;
    }

    if (isLoading) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" p={2}>
          <CircularProgress />
        </Box>
      );
    }

    // Ensure we have valid data before rendering
    const hasValidData = analyticsData && 
                        typeof analyticsData === 'object' && 
                        analyticsData.tag_distribution && 
                        typeof analyticsData.tag_distribution === 'object';

    if (!hasValidData) {
      return (
        <Typography>
          No analytics data available yet.
        </Typography>
      );
    }

    return (
      <>
        <Typography variant="body1" gutterBottom>
          Total Bookmarks: {analyticsData.total_bookmarks || 0}
        </Typography>
        {Object.keys(analyticsData.tag_distribution).length > 0 ? (
          <Box sx={{ height: 300, mt: 2 }}>
            <Bar data={chartData} options={chartOptions} />
          </Box>
        ) : (
          <Typography>
            No tags found. Try adding tags to your bookmarks to see analytics!
          </Typography>
        )}
      </>
    );
  };

  return (
    <Card>
      <CardContent>
        <Box display="flex" alignItems="center" gap={1} mb={2}>
          <InsightsIcon color="primary" />
          <Typography variant="h5" component="h2">
            Bookmark Analytics
          </Typography>
        </Box>

        <Box sx={{ mb: 3 }}>
          <ToggleButtonGroup
            value={period}
            exclusive
            onChange={handlePeriodChange}
            aria-label="Time period"
            size="small"
          >
            {Object.entries(PERIOD_LABELS).map(([value, label]) => (
              <ToggleButton
                key={value}
                value={value}
                aria-label={label}
                disabled={isLoading}
              >
                {label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Box>

        {renderContent()}
      </CardContent>
    </Card>
  );
};

export default BookmarkAnalytics;
