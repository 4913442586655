import { Digest, AnalyticsData } from '../types';
import { safeFetch } from '../utils/api';

// Hardcode HTTPS URL for now
const API_URL = 'https://actify-ai-f2b5e55b470e.herokuapp.com';
console.log('API_URL:', API_URL);

// Helper function to get auth headers
const getHeaders = () => {
  const token = localStorage.getItem('token');
  return {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json',
  };
};

export async function getLatestDigest(): Promise<Digest> {
  const response = await fetch(`${API_URL}/api/digests/latest`, {
    headers: getHeaders(),
  });
  if (!response.ok) {
    if (response.status === 401) {
      throw new Error('Please log in to view digest');
    }
    throw new Error('Failed to fetch latest digest');
  }
  return response.json();
}

export async function getYesterdayBookmarks() {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  
  const response = await fetch(
    `${API_URL}/api/bookmarks?date=${yesterday.toISOString()}`,
    {
      headers: getHeaders(),
    }
  );
  if (!response.ok) {
    if (response.status === 401) {
      throw new Error('Please log in to view bookmarks');
    }
    throw new Error('Failed to fetch yesterday\'s bookmarks');
  }
  return response.json();
}

export async function getAnalytics(period: 'week' | 'month' | 'year'): Promise<AnalyticsData> {
  const response = await fetch(`${API_URL}/api/bookmarks/analytics?period=${period}`, {
    headers: getHeaders(),
  });
  if (!response.ok) {
    if (response.status === 401) {
      throw new Error('Please log in to view analytics');
    }
    throw new Error('Failed to fetch analytics data');
  }
  return response.json();
}

export const checkHealth = async () => {
  const url = `${API_URL}/health`;
  console.log('Checking health at:', url);
  const response = await safeFetch(url);
  return response.ok;
};
