import { Bookmark } from '../types/bookmark';
import { safeFetch } from '../utils/api';

// Hardcode HTTPS URL for now
const API_URL = 'https://actify-ai-f2b5e55b470e.herokuapp.com';
const API_BASE_URL = `${API_URL}/api`;
console.log('Bookmarks API_BASE_URL:', API_BASE_URL);

// Helper function to get auth headers
const getHeaders = () => {
  const token = localStorage.getItem('token');
  return {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json',
  };
};

export const fetchBookmarks = async (date?: Date): Promise<Bookmark[]> => {
  const params = date ? `date=${date.toISOString().split('T')[0]}` : '';
  const url = `${API_BASE_URL}/bookmarks${params ? '?' + params : ''}`;
  console.log('Fetching bookmarks from:', url);
  const response = await safeFetch(url, {
    headers: getHeaders(),
  });

  if (!response.ok) {
    const error = await response.text();
    throw new Error(error || 'Failed to fetch bookmarks');
  }

  return response.json();
};

export const fetchAnalytics = async (period: 'week' | 'month' | 'year') => {
  const url = `${API_BASE_URL}/bookmarks/analytics?period=${period}`;
  console.log('Fetching analytics from:', url);
  const response = await safeFetch(url, {
    headers: getHeaders(),
  });

  if (!response.ok) {
    if (response.status === 401) {
      throw new Error('Please log in to view analytics');
    }
    const error = await response.text();
    throw new Error(error || 'Failed to fetch analytics');
  }

  return response.json();
};

export const syncBookmarks = async (bookmarks: Bookmark[]) => {
  const url = `${API_BASE_URL}/bookmarks/sync`;
  console.log('Syncing bookmarks to:', url);
  const response = await safeFetch(url, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ bookmarks }),
  });

  if (!response.ok) {
    const error = await response.text();
    throw new Error(error || 'Failed to sync bookmarks');
  }

  return response.json();
};

export const fetchDigest = async () => {
  const url = `${API_BASE_URL}/digests/latest`;
  console.log('Fetching digest from:', url);
  const response = await safeFetch(url, {
    headers: getHeaders(),
  });

  if (!response.ok) {
    if (response.status === 401) {
      throw new Error('Please log in to view digest');
    }
    const error = await response.text();
    throw new Error(error || 'Failed to fetch digest');
  }

  return response.json();
};
