export const ensureHttps = (url: string): string => {
  // If it's localhost, keep the protocol as is
  if (url.includes('localhost')) {
    return url;
  }

  // Remove any existing protocol
  const urlWithoutProtocol = url.replace(/^(https?:\/\/)/, '');
  
  // Add https:// protocol
  return `https://${urlWithoutProtocol}`;
};
