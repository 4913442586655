import React from 'react';
import { Card, CardContent, Typography, Box, Chip, Stack, CircularProgress, IconButton } from '@mui/material';
import ArticleIcon from '@mui/icons-material/Article';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { fetchBookmarks } from '../api/bookmarks';
import { getLatestDigest } from '../api';

interface Digest {
  summary: string;
  key_insights: string[];
  topics: string[];
  recommendations: string[];
  bookmark_count: number;
  generated_at: string;
  top_tags: string[];
  podcast_url?: string;
}

const AudioPlayer: React.FC<{ url: string }> = ({ url }) => {
  const [isPlaying, setIsPlaying] = React.useState(false);
  const audioRef = React.useRef<HTMLAudioElement>(null);

  const togglePlay = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  React.useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      audio.addEventListener('ended', () => setIsPlaying(false));
      return () => {
        audio.removeEventListener('ended', () => setIsPlaying(false));
      };
    }
  }, []);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 2 }}>
      <IconButton onClick={togglePlay} color="primary" size="large">
        {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
      </IconButton>
      <Typography variant="body2" color="textSecondary">
        {isPlaying ? 'Playing digest podcast...' : 'Play digest podcast'}
      </Typography>
      <audio ref={audioRef} src={url} />
    </Box>
  );
};

const DailySummary: React.FC = () => {
  const [todayCount, setTodayCount] = React.useState<number>(0);
  const [digest, setDigest] = React.useState<Digest | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);

  React.useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        console.log('Fetching today\'s bookmarks...');
        // Get today's bookmarks using local timezone
        const today = new Date();
        // Format date in local timezone
        const localDate = today.toLocaleDateString('en-CA'); // YYYY-MM-DD format
        console.log('Fetching bookmarks for date:', localDate);
        const bookmarksData = await fetchBookmarks(new Date(localDate));
        console.log('Today\'s bookmarks:', bookmarksData);
        setTodayCount(bookmarksData.length);

        console.log('Fetching digest...');
        // Get yesterday's digest
        const digestData = await getLatestDigest();
        console.log('Digest data:', digestData);
        setDigest(digestData);
        
        setError(null);
      } catch (err) {
        const errorMessage = err instanceof Error ? err.message : 'Failed to load data';
        console.error('Error details:', err);
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  if (loading) {
    return (
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Box display="flex" justifyContent="center" alignItems="center" p={2}>
            <CircularProgress />
          </Box>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Box display="flex" alignItems="center" gap={1} mb={2}>
          <ArticleIcon color="primary" />
          <Typography variant="h5" component="h2">
            Bookmark Summary
          </Typography>
        </Box>
        
        {error ? (
          <>
            <Typography color="error">{error}</Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
              Error occurred while loading data. Please check the console for details.
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="body1" gutterBottom>
              You have saved {todayCount} bookmark{todayCount !== 1 ? 's' : ''} today.
            </Typography>

            {digest && (
              <>
                <Typography variant="body1" gutterBottom>
                  Yesterday, you saved {digest.bookmark_count} bookmark{digest.bookmark_count !== 1 ? 's' : ''}.
                </Typography>

                {digest.podcast_url && (
                  <AudioPlayer url={digest.podcast_url} />
                )}

                <Box sx={{ mt: 2 }}>
                  <Typography variant="h6" gutterBottom>
                    Summary
                  </Typography>
                  <Typography variant="body1" paragraph>
                    {digest.summary}
                  </Typography>
                </Box>

                {digest.key_insights.length > 0 && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="h6" gutterBottom>
                      Key Insights
                    </Typography>
                    <ul>
                      {digest.key_insights.map((insight, index) => (
                        <li key={index}>
                          <Typography variant="body1">{insight}</Typography>
                        </li>
                      ))}
                    </ul>
                  </Box>
                )}

                {digest.topics.length > 0 && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="h6" gutterBottom>
                      Topics
                    </Typography>
                    <Stack direction="row" spacing={1} flexWrap="wrap">
                      {digest.topics.map((topic, index) => (
                        <Chip 
                          key={index}
                          label={topic}
                          color="secondary"
                          variant="outlined"
                          size="small"
                        />
                      ))}
                    </Stack>
                  </Box>
                )}

                {digest.recommendations.length > 0 && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="h6" gutterBottom>
                      Recommendations
                    </Typography>
                    <ul>
                      {digest.recommendations.map((recommendation, index) => (
                        <li key={index}>
                          <Typography variant="body1">{recommendation}</Typography>
                        </li>
                      ))}
                    </ul>
                  </Box>
                )}

                {digest.top_tags.length > 0 && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="h6" gutterBottom>
                      Top Tags
                    </Typography>
                    <Stack direction="row" spacing={1} flexWrap="wrap">
                      {digest.top_tags.map((tag, index) => (
                        <Chip 
                          key={index}
                          label={tag}
                          color="primary"
                          variant="outlined"
                          size="small"
                        />
                      ))}
                    </Stack>
                  </Box>
                )}
              </>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default DailySummary;
