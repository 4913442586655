import { safeFetch } from '../utils/api';

// Hardcode HTTPS URL for now
const API_URL = 'https://actify-ai-f2b5e55b470e.herokuapp.com';
const API_BASE_URL = `${API_URL}/api`;
console.log('Auth API_BASE_URL:', API_BASE_URL);

export const login = async (email: string, password: string) => {
  const formData = new FormData();
  formData.append('username', email);  // OAuth2 expects 'username' field
  formData.append('password', password);

  const url = `${API_BASE_URL}/auth/token`;
  console.log('Logging in at:', url);
  const response = await safeFetch(url, {
    method: 'POST',
    body: formData
  });

  if (!response.ok) {
    const error = await response.text();
    throw new Error(error || 'Failed to login');
  }

  const data = await response.json();
  localStorage.setItem('token', data.access_token);
  return data;
};

export const register = async (email: string, password: string) => {
  const url = `${API_BASE_URL}/auth/register`;
  console.log('Registering at:', url);
  const response = await safeFetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, password })
  });

  if (!response.ok) {
    const error = await response.text();
    throw new Error(error || 'Failed to register');
  }

  const data = await response.json();
  localStorage.setItem('token', data.access_token);
  return data;
};
