import { ensureHttps } from './url';

export const safeFetch = (url: string, options?: RequestInit) => {
  // First ensure HTTPS
  const secureUrl = ensureHttps(url);
  
  // Then clean up any double slashes (except after protocol)
  const cleanUrl = secureUrl.replace(/([^:]\/)\/+/g, '$1');
  
  // Remove any trailing slashes before query params
  const finalUrl = cleanUrl.replace(/\/\?/, '?');
  
  console.log('Original URL:', url);
  console.log('Final URL:', finalUrl);
  
  return fetch(finalUrl, options);
};
